import GlobalLayout from "components/page/GlobalLayout";
import { Helmet } from "react-helmet";
import React from "react";
import WebinarPageV2 from "components/webinar/WebinarPageV2";

export default function Hotels() {
  return (
    <GlobalLayout>
      <Helmet>
        <title>Rhombus - Hotel Video Security & Surveillance Webinar</title>
        <meta
          name="description"
          content="Learn about hotel security cameras and environmental sensors in the next live webinar."
        />
      </Helmet>
      <WebinarPageV2 title="Modern Cloud Video Security for Hotels" />
    </GlobalLayout>
  );
}
